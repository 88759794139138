/* GENERATED FILE */
import React, { forwardRef } from "react";

import {
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from "../lib";
import IconBase, { RenderFunction } from "../lib/IconBase";

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set("bold", (color: string) => (
  <>
    <path
      d="M223.9944,127.41159a95.69791,95.69791,0,0,1-28.17312,68.53158c-36.94566,36.88079-97.30608,37.29538-134.72958.89956a95.99643,95.99643,0,0,1,67.48515-164.8372,8.01374,8.01374,0,0,1,7.80464,9.7612,31.99345,31.99345,0,0,0,30.85987,39.031,8.02776,8.02776,0,0,1,7.96088,7.96088,31.99346,31.99346,0,0,0,39.03035,30.86A8.01158,8.01158,0,0,1,223.9944,127.41159Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <circle cx="156" cy="172" r="16" />
    <circle cx="92" cy="164" r="16" />
    <circle cx="84" cy="108" r="16" />
    <circle cx="136" cy="124" r="16" />
  </>
));

pathsByWeight.set("duotone", (color: string) => (
  <>
    <path
      d="M223.9944,127.41159a95.69791,95.69791,0,0,1-28.17312,68.53158c-36.94566,36.88079-97.30608,37.29538-134.72958.89956a95.99643,95.99643,0,0,1,67.48515-164.8372,8.01374,8.01374,0,0,1,7.80464,9.7612,31.99345,31.99345,0,0,0,30.85987,39.031,8.02776,8.02776,0,0,1,7.96088,7.96088,31.99346,31.99346,0,0,0,39.03035,30.86A8.01158,8.01158,0,0,1,223.9944,127.41159Z"
      opacity="0.2"
    />
    <path
      d="M223.9944,127.41159a95.69791,95.69791,0,0,1-28.17312,68.53158c-36.94566,36.88079-97.30608,37.29538-134.72958.89956a95.99643,95.99643,0,0,1,67.48515-164.8372,8.01374,8.01374,0,0,1,7.80464,9.7612,31.99345,31.99345,0,0,0,30.85987,39.031,8.02776,8.02776,0,0,1,7.96088,7.96088,31.99346,31.99346,0,0,0,39.03035,30.86A8.01158,8.01158,0,0,1,223.9944,127.41159Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <circle cx="156" cy="172" r="12" />
    <circle cx="92" cy="164" r="12" />
    <circle cx="84" cy="108" r="12" />
    <circle cx="136" cy="124" r="12" />
  </>
));

pathsByWeight.set("fill", () => (
  <>
    <path d="M225.94092,114.89844a15.91968,15.91968,0,0,0-13.46094-3.085,23.99389,23.99389,0,0,1-29.27832-23.14063,15.94771,15.94771,0,0,0-15.87354-15.875A23.99268,23.99268,0,0,1,144.187,43.51953a16.01308,16.01308,0,0,0-15.562-19.51367c-.21729-.001-.43018-.00195-.647-.00195A104,104,0,1,0,231.99463,127.3623v-.001A15.97461,15.97461,0,0,0,225.94092,114.89844ZM75.51465,99.51465a12.0001,12.0001,0,1,1,0,16.9707A12.00062,12.00062,0,0,1,75.51465,99.51465Zm24.9707,72.9707a12.0001,12.0001,0,1,1,0-16.9707A12.00062,12.00062,0,0,1,100.48535,172.48535Zm27.0293-40a12.0001,12.0001,0,1,1,16.9707,0A12.00019,12.00019,0,0,1,127.51465,132.48535Zm36.9707,48a12.0001,12.0001,0,1,1,0-16.9707A12.00062,12.00062,0,0,1,164.48535,180.48535Z" />
  </>
));

pathsByWeight.set("light", (color: string) => (
  <>
    <path
      d="M223.9944,127.41159a95.69791,95.69791,0,0,1-28.17312,68.53158c-36.94566,36.88079-97.30608,37.29538-134.72958.89956a95.99643,95.99643,0,0,1,67.48515-164.8372,8.01374,8.01374,0,0,1,7.80464,9.7612,31.99345,31.99345,0,0,0,30.85987,39.031,8.02776,8.02776,0,0,1,7.96088,7.96088,31.99346,31.99346,0,0,0,39.03035,30.86A8.01158,8.01158,0,0,1,223.9944,127.41159Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <circle cx="156" cy="172" r="10" />
    <circle cx="92" cy="164" r="10" />
    <circle cx="84" cy="108" r="10" />
    <circle cx="136" cy="124" r="10" />
  </>
));

pathsByWeight.set("thin", (color: string) => (
  <>
    <path
      d="M223.9944,127.41159a95.69791,95.69791,0,0,1-28.17312,68.53158c-36.94566,36.88079-97.30608,37.29538-134.72958.89956a95.99643,95.99643,0,0,1,67.48515-164.8372,8.01374,8.01374,0,0,1,7.80464,9.7612,31.99345,31.99345,0,0,0,30.85987,39.031,8.02776,8.02776,0,0,1,7.96088,7.96088,31.99346,31.99346,0,0,0,39.03035,30.86A8.01158,8.01158,0,0,1,223.9944,127.41159Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <circle cx="156" cy="172" r="8" />
    <circle cx="92" cy="164" r="8" />
    <circle cx="84" cy="108" r="8" />
    <circle cx="136" cy="124" r="8" />
  </>
));

pathsByWeight.set("regular", (color: string) => (
  <>
    <path
      d="M223.9944,127.41159a95.69791,95.69791,0,0,1-28.17312,68.53158c-36.94566,36.88079-97.30608,37.29538-134.72958.89956a95.99643,95.99643,0,0,1,67.48515-164.8372,8.01374,8.01374,0,0,1,7.80464,9.7612,31.99345,31.99345,0,0,0,30.85987,39.031,8.02776,8.02776,0,0,1,7.96088,7.96088,31.99346,31.99346,0,0,0,39.03035,30.86A8.01158,8.01158,0,0,1,223.9944,127.41159Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <circle cx="156" cy="172" r="12" />
    <circle cx="92" cy="164" r="12" />
    <circle cx="84" cy="108" r="12" />
    <circle cx="136" cy="124" r="12" />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

const Cookie = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <IconBase ref={ref} {...props} renderPath={renderPath} />
));

Cookie.displayName = "Cookie";

export default Cookie;
